.Cms_SeoBlock,
.Cms_CategorySeoBlock {
    max-width: 1390px;
    margin: 0 auto;
    padding-block: 0;

    @include desktop {
        padding-inline: 15px;
    }

    @include mobile {
        margin-inline: 14px;
    }

    .Typography_h6 {
        margin-block-end: 10px;
    }
}