/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.HomePage {
    @include mobile {
        margin-top: calc(var(--header-total-height) + 90px);
    }

    .CmsPage {

        &,
        &-Wrapper {
            margin-block-end: 0;
            margin-block-start: 0;
        }

        &-Wrapper {
            @include desktop {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;

                .CmsPage-Content {
                    >div {
                        >div:not(.full-width) {
                            max-width: var(--content-wrapper-width);
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
        }

        &-Content {
            @include mobile {
                overflow-x: hidden;
            }

            .ProductList.ProductListWidget,
            .RecommendedCategories {
                h2 {
                    text-align: left;

                    @include mobile {
                        font-size: 24px;
                    }
                }
            }

            .ProductList.ProductListWidget {
                @include mobile {
                    padding-inline: 14px;
                }
            }
        }
    }
}