@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 700;
    font-display: fallback;
    src: local('OpenSans Bold'), local('OpenSans-bold'), url('/style/fonts/OpenSans-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 400;
    font-display: fallback;
    src: local('OpenSans Regular'), local('OpenSans-regular'), url('/style/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-style: normal;
    font-weight: 600;
    font-display: fallback;
    src: local('OpenSans SemiBold'), local('OpenSans-semibold'), url('/style/fonts/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    font-style: italic;
    font-weight: 400;
    font-display: fallback;
    src: local('OpenSans Italic'), local('OpenSans-italic'), url('/style/fonts/OpenSans-Italic.ttf') format('truetype');
}
