/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SalableQtyErrorPopup {

    &-Content {
        min-height: min-content;

        ul {
            max-height: 300px;
            overflow-y: auto;
        }

        @include mobile {
            margin: 0;
        }

        .Field {
            margin-top: 20px;

            @include mobile {
                margin-top: 12px;
            }

            &-RadioLabel {
                align-items: flex-start;

                .input-control {
                    margin-top: 2px;
                }
            }
        }
    }

    &-Header {
        color: $font-color-1;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;

        @include mobile {
            font-size: 16px;
            line-height: 20px;
        }
    }

    &-Label {
        display: flex;
        flex-direction: column;
        max-width: 397px;

        span {
            display: block;

            &:nth-of-type(1) {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 4px;
            }

            &:nth-of-type(2) {
                color: $font-color-2;
                font-size: 14px;
                line-height: 20px;

                @include mobile {
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
    }

    &-Overlay {
        align-items: center;
        background: rgba(0, 0, 0, 0.5);
        bottom: 0;
        display: none;
        justify-content: center;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 1000;

        @include mobile {
            align-items: flex-end;
        }

        &_isVisible {
            display: flex;
        }
    }

    &-Product {
        border-top: 1px $neutral-color-6 solid;
        display: grid;
        gap: 8px;
        grid-template-columns: 100px 1fr;
        padding: 8px 0;

        &:last-child {
            border-bottom: 1px $neutral-color-6 solid;
            margin-bottom: 0;
        }

        &::before {
            display: none;
        }

        .AvailabilityStatus {
            &-Bar {
                height: 10px;

                @include mobile {
                    height: 8px;
                }
            }

            &-Qty {
                font-size: 14px;

                @include mobile {
                    font-size: 12px;
                }

                &_isUnavailable {
                    color: $status-red-dark;
                }
            }
        }

        &Details {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        &Image {
            max-height: 100px;
            max-width: 100px;
        }

        &Name {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;

            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }

        &Price {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            text-align: right;

            @include mobile {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }

    &-SaveButton {
        margin-top: 16px;
        width: 100%;
    }

    &-Wrapper {
        background: $white;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 496px;
        max-width: 498px;
        padding: 30px 35px;

        @include mobile {
            border-radius: 4px 4px 0 0;
            min-height: 420px;
            padding: 16px 14px;
        }
    }
}