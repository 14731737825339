/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.NewsSection {
    &-Post {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mobile {
            display: block;
            text-align: center;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            display: none;
        }

        &Title {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: $font-color-1;
            display: flex;
            align-items: flex-start;

            @include mobile {
                text-align: left;
            }

            @include mobile {
                font-size: 18px;
                line-height: 24px;
                margin-bottom: 10px;
            }

            svg {
                margin-right: 12px;
                min-width: 25px;
                position: relative;
                top: -2px;

                @include mobile {
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                    top: 2px;
                }
            }
        }

        .Button {
            min-height: 36px;
            height: 36px;
            line-height: 18px;
            font-weight: 700;
            font-size: 14px;
            min-width: 147px;
            padding: 9px 20px;

            @include mobile {
                width: auto;
            }
        }
    }

    &-Wrapper {
        background: $secondary-color-7;
        padding: 20px 30px;
        border-radius: 2px;
        margin: 25px auto;

        @include mobile {
            padding: 20px;
            margin: 20px 14px 40px;
        }
    }
}
