.HomepageUsp {
    display: flex;
    justify-content: center;
    margin: 0 auto 25px;
    background: $neutral-color-8;
    min-height: 80px;

    @include desktop {
        column-gap: 60px;
        padding-inline: 32px;
    }

    @include wide-desktop {
        column-gap: 100px;
    }

    @include mobile {
        display: none;
    }

    &-Item {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-1;
        display: flex;
        align-items: center;

        @include mobile {
            justify-content: center;
            width: 50%;
            min-width: 200px;
        }

        img {
            margin-right: 10px;
            width: auto;
            height: auto;
            max-width: 84px;
        }
    }
}
