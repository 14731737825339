/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.FastOrder {
    &-Content {
        display: grid;
        gap: 16px;
        grid-template-columns: 1fr 1px 1fr;
        margin-top: 16px;

        @include mobile {
            grid-template-columns: 1fr;
        }

        button {
            width: 100%;
        }

        input {
            border: 0;
        }

        textarea {
            margin-top: 16px;
            min-height: 100px;
            resize: vertical;
            width: 100%;
        }
    }

    &-Divider {
        background-color: #AAAAAA;

        @include mobile {
            height: 1px;
            width: 100%;
        }
    }

    &-LeftSection,
    &-RightSection {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        gap: 16px;
    }
}