/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.NavigationTabs {
    background-color: $neutral-color-7;
    border-radius: 2px 2px 0px 0px;
    border-top: none;
    box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.12);
    padding: 0;

    &_isIos {
        @include mobile {
            padding: 7px 0 20px 0;
        }
    }

    .hideOnScroll & {
        transform: translateY(0);
    }

    &-Button {
        color: $font-color-2;
        display: flex;
        flex-direction: column;

        &:not(.NavigationTabs-Button_isMenuIcon) svg path {
            fill: $font-color-2;
        }

        .NavigationTabs-Button_isMenuIcon svg path {
            stroke: $font-color-2;
        }

        &_isActive {
            color: $primary-color-1;

            &:not(.NavigationTabs-Button_isMenuIcon) svg path {
                fill: $primary-color-1;
            }

            &.NavigationTabs-Button_isMenuIcon svg path {
                stroke: $primary-color-1;
            }
        }
    }
}