.ContactsFooter {
    @include desktop {
        margin-top: 8px;
    }

    &-Row {
        display: flex;
        align-items: center;
        gap: 14px;

        &-Icon {
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
        }

        .Typography_p1 {
            color: $neutral-color-9;
        }
    }
    
    .Row-Mail {
        margin-block-end: 33px;

        .Mail-Icon {
            background-image: url("../../assets/icons/mail-white.svg");  
        }
    }

    .Row-Location {
        p {
            font-family: "OpenSans", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $neutral-color-9;
            text-align: left;
            margin-bottom: 0;
        }

        .Location-Icon  {
            background-image: url("../../assets/icons/map-pin.svg");
        } 
    }
}
