.HighlightedCategories {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 20px;
    margin-bottom: 80px;

    @include mobile {
        gap: 12px;
        display: flex;
        flex-wrap: nowrap;
        padding-inline: 14px;
        margin-bottom: 40px;
        overflow-x: scroll;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        touch-action: manipulation;

        &::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
        }
    }

    &-Block {
        text-decoration: none;
        padding: 0;
        margin: 0;
        
        @include mobile {
            &:nth-child(1), &:nth-child(2) {
                flex: 1 1 calc(50% - 6px);
            }
    
            &:nth-child(3) {
                flex: 1 1 100%;
            }
        }
    }

    &-SecondBlock {
        padding: 0;
        margin: 0;

        @include desktop {
            max-height: 406px;
        }
        

        @include desktop {
            &:nth-child(1) {
                min-width: 670px;
            }
            
            &:nth-child(2), &:nth-child(3) {
                min-width: 325px;
            }
        }

        @include mobile {
            &:nth-child(1) {
                img {
                    width: calc(100vw - 26px);
                }
            }

            img {
                width: calc(50vw - 19px);
                height: 200px;
                max-height: 200px;
            }
        }
    }

    &-Desc {
        position: absolute;
        left: 20px;
        bottom: 20px;
        display: flex;
        flex-direction: column;

        @include mobile {
            bottom: 12px;
            left: 12px;
        }

        span, div {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 6px;
            color: $white;
        }

        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: $secondary-color-1;
            text-decoration: none;
            text-transform: capitalize;
            display: flex;

            .RenderWhenVisible {
                display: inline-flex;
                margin-left: 3px;
                align-self: center;
                margin-bottom: 0;
            }

            &:hover {
                color: $primary-color-1;
                svg {
                    path {
                        stroke: $primary-color-1;
                    }
                }
            }
        }

        @include desktop {
            span, div {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}