.CheckoutFooter {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width:  1320px;
    padding-inline: 14px;
    padding-block: 20px;

    &-Links {
        display: flex;
        gap: 16px;
        text-decoration: underline;
    }

    @include tablet {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        gap: 20px;

        &-Links {
            justify-content: center;
        }
    }

    &-Copyright, .link {
        font-family: $font-open-sans;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-2;
    }

    .link:hover {
        color: $primary-color-1;
    }
}