/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Header {
    --header-logo-width: 138px;
    --header-logo-height: 44px;

    height: 100%;
    position: relative;

    @include mobile {
        box-shadow: 0px 2px 14px rgba(0, 0, 0, 0.12);
        border-bottom: 0;
    }

    &-FastAddButton {
        cursor: pointer;
        display: flex;
        margin-right: 16px;

        span {
            left: 11px;
            font-weight: 700;
            position: absolute;
            top: 3px;
        }
    }

    &_isCheckout {
        .Header-Nav {
            @include desktop {
                min-height: 74px;
                margin: 0 auto;
                padding: 0 32px;
            }
        }

        .Header-MyAccount {
            @include desktop {
                min-width: 200px;
            }
        }

        .Header-LogoWrapper {
            @include desktop {
                position: absolute;
                left: 32px;
            }
        }

    }

    &-Title {
        @include mobile {
            display: flex;
            justify-content: center;

            span {
                font-weight: 600;
                font-size: 16px;
            }
        }
    }

    &-Wrapper {
        margin-bottom: 0;

        @include mobile {
            position: fixed;
            top: 0;
            width: 100%;
        }
    }

    &-Button {
        &_type_minicart {
            margin-left: 0 !important;
        }

        &_type_back {
            position: relative;

            svg path {
                stroke: $font-color-1;
            }

            &::after {
                content: '';
                display: block;
                width: 1px;
                height: 44px;
                background-color: $font-color-3;
                position: absolute;
                right: -18px;
                top: -10px;
            }

            .ChevronIcon {
                top: 2px;
            }
        }
    }

    &-CheckOrderStatus {
        font-weight: 600;
        text-transform: uppercase;

        &:hover {
            color: $primary-color-1;
        }
    }

    &-Contact {
        align-items: center;
        display: inline-flex;
        gap: 8px;
        line-height: 20px;

        &:nth-child(3) {
            text-transform: uppercase;
        }
    }

    &-Consultant {
        display: flex;
        column-gap: 17px;
    }

    &-LeftContent {
        display: flex;
        align-items: center;
        gap: 20px;

        .Header-Contact {

            a,
            span {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: $font-color-1;
                text-transform: none;
            }

            &:hover {
                a {
                    color: $primary-color-1;
                }

                path {
                    stroke: $primary-color-1;
                }
            }


            &_isName {
                svg {
                    width: 27px;
                }

                &:hover {
                    path {
                        stroke: $font-color-1;
                    }
                }
            }
        }

        .Header-ConsultantLink a {
            align-items: center;
            color: $font-color-1;
            cursor: pointer;
            display: flex;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;

            &:hover {
                color: $primary-color-1;
            }
        }
    }

    &-MyAccountWrapper {
        align-items: center;
        cursor: pointer;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        text-transform: uppercase;
        color: $font-color-4;

        &:hover {
            color: $primary-color-1;

            path {
                stroke: $primary-color-1;
            }
        }

        &_isCheckout {
            @include desktop {
                margin-left: auto;
            }
        }
    }

    &-RightContent {
        align-items: center;
        display: flex;
        gap: 20px;
    }

    &-TopMenuWrapper {
        color: $font-color-1;
        display: flex;
        font-size: 14px;
        justify-content: space-between;
        gap: 30px;
        list-style: none;
        margin: 0 auto;
        max-width: var(--content-wrapper-width);
        padding: 0;
        width: 100%;

        &_isCheckout {

            @include desktop {
                padding: 0 32px;
            }
        }
    }

    &-IconsWrapper {
        grid-column: 3 / 4;
    }

    &-LogoWrapper {
        min-height: 44px;
        max-width: 138px;

        @include desktop {
            height: 100%;
        }
    }

    &-FavouritesButtonWrapper {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-transform: uppercase;
        color: $font-color-4;
        margin-left: -12px;
        margin-right: 20px;

        &:hover {
            color: $font-color-4;
        }
    }

    &-FavouritesItemCount {
        border-radius: 8px;
        height: 17px;
        padding: 2px;
        position: absolute;
        min-width: 17px;
        display: flex;
        font-size: 11px;
        justify-content: center;
        align-items: center;
        font-weight: 700;

        @include mobile {
            inset-block-start: -7px;
            inset-inline-end: -11px;
        }

        @include desktop {
            inset-block-start: -10px;
            inset-inline-start: 20px;
        }
    }

    &-FavouritesButtonWrapper,
    &-MinicartButtonWrapper {
        align-items: center;
        display: flex;
        font-size: 14px;
        font-weight: 600;
        gap: 10px;

        svg {
            min-width: 25px;
        }
    }

    &-MinicartButtonWrapper {
        margin-left: 0;
    }

    &-FavouritesItemCount,
    &-MinicartItemCount {
        background: $primary-color-1;
        transform: translateX(-5px);
        color: $secondary-color-1;
    }

    &-MinicartItemCount {
        @include mobile {
            font-weight: 400;
            font-size: 10px;
            line-height: 16px;
            min-width: 21px;
            height: 21px;
            right: -15px;
            border-radius: 50%;
        }
    }

    &-Nav {
        height: auto;
        gap: 20px;
        grid-template-columns: 138px 1fr 93px;
        margin-top: 25px;

        @include desktop {
            padding-left: 0;
        }

        @include mobile {
            min-height: 44px;
            margin-top: 0;
        }

        &_isLoggedIn {
            grid-template-columns: 138px 1fr 198px;
        }
    }

    &_name_default,
    &_name_search {
        .Header-Nav {
            @include mobile {
                flex-direction: column;
                margin-top: 25px;
            }
        }
    }

    &-TopMenu {
        align-items: center;
        background: $neutral-color-8;
        color: $font-color-1;
        height: 48px;
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;
        padding-top: 0;

        a {
            color: $font-color-1;
        }
    }

    &_name_popup {
        .Header-Button_type_close {
            @include mobile {
                position: absolute;
                right: 20px;
                top: 8px;
            }
        }

        .Header-Title {
            @include mobile {
                font-size: 27px;
            }
        }

        .Header-Nav {
            @include mobile {
                margin-bottom: 8px;
                margin-top: 10px;
            }
        }
    }

    .TopBar {
        background: $secondary-color-1;
        border-radius: 0 0 4px 4px;
        color: $white;
        max-width: 100%;
        padding: 13px 53px;
        position: relative;
        text-align: center;

        &-CloseButton {
            cursor: pointer;
            position: absolute;
            right: 23px;
            top: 10px;
        }
    }

    &-UserInfo {
        margin-top: 5px;
        p {
            font-size: 14px;
            font-weight: 600;
            margin: 0;
            line-height: 1.2;
        }
    }
}
