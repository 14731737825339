/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.Menu {
    &-Item {
        &:nth-last-child(2) {
            margin-left: auto !important;
        }
    }

    &-ItemCaption {
        font-size: 14px;
        font-weight: 600;
        color: $font-color-1;

        &_type_subcategory {
            margin-top: 0;
        }

        @include mobile {
            font-size: 18px;

            &_type_main {
                margin-inline: 14px;
                padding-block: 15px;
            }

            &_isSecondLevel {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                margin-left: 0;
                padding-block: 0 !important;
            }
        }
    }

    &-ItemList {
        @include mobile {
            padding: 10px 0;

            &_type_main {
                margin-top: 18px;
            }

            &_type_subcategory {
                padding-block: 0;
            }
        }

        &_hideElements {
            @include desktop {
                margin-bottom: 16px;
            }

            .right_menu_item {
                display: none;
            }
        }
    }


    &-MainCategories {
        margin: inherit;
        width: 100%;

        @include desktop {
            .Menu-Link {
                font-weight: 400;
            }
        }
    }

    &-MenuWrapper {
        display: flex;

        padding-inline: 0;
    }

    &-Sub {
        &Categories {
            min-width: 360px;
            padding: 10px 10px 20px;

            .Menu {
                &-ItemList {
                    display: flex;
                    flex-direction: column;
                    gap: 6px;
                    padding: 0;
                }
            }
        }

        &CategoriesWrapper {
            background: $white;
            box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
            border-radius: 0px 0px 12px 12px;
            left: 0;
            margin-top: -1px;
            position: absolute;
            top: 31px;
        }

        &ItemWrapper {
            border-radius: 4px;
            cursor: pointer;
            padding: 2px 10px;

            &:hover {
                background: $extra-color-7;
            }

            @include mobile {
                padding: 10px;
            }
        }

        &Menu {
            margin: 0;

            @include mobile {
                background: $extra-color-7;
                margin: 0 16px;
                padding: 0;
            }

            .Menu-ItemCaption {
                @include desktop {
                    padding-block-end: 0;
                }
            }
        }
    }
}