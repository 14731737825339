.HomepagePromoBlocks {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    gap: 20px;
    margin-block: 22px 100px;

    @include mobile {
        gap: 12px;
        display: flex;
        flex-wrap: wrap;
        padding-inline: 14px;
        margin-block: 0 50px;
    }

    &-Block {
        text-decoration: none;
        padding: 0;
        margin: 0;

        @include desktop {
            max-height: 340px;
        }

        @include mobile {

            &:nth-child(1),
            &:nth-child(2) {
                flex: 1 1 calc(50% - 6px);
            }

            &:nth-child(3) {
                flex: 1 1 100%;
            }
        }
    }

    &-SecondBlock {
        padding: 0;
        margin: 0;

        @include desktop {
            min-height: 406px;

            &:nth-child(1) {
                min-width: 670px;
            }

            &:nth-child(2),
            &:nth-child(3) {
                min-width: 325px;
            }
        }

        @include mobile {
            &:nth-child(1) {
                img {
                    width: calc(100vw - 26px);
                }
            }

            img {
                width: calc(50vw - 19px);
                height: 200px;
                max-height: 200px;
            }
        }
    }

    &-Desc {
        position: absolute;
        left: 12px;
        bottom: 12px;
        display: flex;
        flex-direction: column;

        @include mobile {
            bottom: 9px;
        }

        span,
        div {
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: $font-color-1;

            @include mobile {
                font-size: 16px
            }

            &:last-of-type {
                margin-bottom: 9px;

                @include desktop {
                    margin-bottom: 19px;
                }
            }
        }

        a {
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: $primary-color-1;
            text-decoration: none;
            text-transform: capitalize;
            display: flex;

            @include mobile {
                font-size: 16px
            }

            .RenderWhenVisible {
                display: inline-flex;
                margin-left: 3px;
                align-self: center;
                margin-bottom: 0;
            }

            &:hover {
                color: $extra-color-1;

                svg {
                    path {
                        stroke: $extra-color-1;
                    }
                }
            }
        }

        @include desktop {
            left: 30px;
            bottom: 30px;

            span,
            div {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }
}

.HomepageSecondPromoBlocks {
    @include desktop {
        margin-top: 12px;
    }

    &-Desc {

        span,
        div {
            &:last-of-type {
                margin-bottom: 9px;
            }
        }
    }

    @include mobile {
        overflow-x: scroll;
        flex-wrap: nowrap;
        -ms-overflow-style: none;
        /* for Internet Explorer, Edge */
        scrollbar-width: none;
        /* for Firefox */
        touch-action: manipulation;

        &::-webkit-scrollbar {
            display: none;
            /* for Chrome, Safari, and Opera */
        }
    }
}