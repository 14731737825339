/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

$white: #fff;
$black: #000;
$default-primary-base-color: #94cf00;
$default-primary-dark-color: darken($default-primary-base-color, 15%);
$default-primary-light-color: lighten($default-primary-base-color, 15%);
$default-secondary-base-color: #eee;
$default-secondary-dark-color: darken($default-secondary-base-color, 15%);
$default-secondary-light-color: lighten($default-secondary-base-color, 15%);
$font-open-sans: "OpenSans", sans-serif;
$font-standard-size: 16px;

$font-color-1: #383838;
$font-color-2: #626262;
$font-color-3: #989898;
$font-color-4: #252525;

$success-color: #2fbf71;
$error-color: #f23d3d;
$disabled-color: #cbcbcb;
$availability-color: #36B587;
$yellow-status-color: #fab100;

$status-orange-light: #FDD8AC;
$status-orange-dark: #E97D00;
$status-red-light: #FFD0D1;
$status-red-dark: #BF0007;
$status-green-light: #E1E9CD;

$btn-color1: #cbcbcb;

$primary-color-1: #94CF00;
$primary-color-2: #8CB524;
$primary-color-3: #BAE846;
$primary-color-4: #C9F261;
$primary-color-5: #D9FC7E;
$primary-color-6: #E7FCB0;

$secondary-color-1: #073836;
$secondary-color-2: #041F1E;
$secondary-color-3: #1B5957;
$secondary-color-4: #40807D;
$secondary-color-5: #8FCCCA;
$secondary-color-6: #B8E6E3;
$secondary-color-7: #B8E5E3;

$extra-color-1: #FF7800;
$extra-color-2: #E67817;
$extra-color-3: #FFA14D;
$extra-color-4: #FFBC80;
$extra-color-5: #FFD0A6;
$extra-color-6: #FFEBD9;
$extra-color-7: #F7F9FF;
$extra-color-8: #d8f0c0;
$extra-color-9: #E1FDEC;
$extra-color-10: #0B680C;
$extra-color-11: #D0ECFC;
$extra-color-12: #0476A9;
$extra-color-13: #FFF1D5;
$extra-color-14: #946700;
$extra-color-15: #FFE7E8;
$extra-color-16: #BF0007;

$neutral-color-1: #262626;
$neutral-color-2: #333333;
$neutral-color-3: #626262;
$neutral-color-4: #989898;
$neutral-color-5: #CCCCCC;
$neutral-color-6: #E6E6E6;
$neutral-color-7: #F9F9F9;
$neutral-color-8: #F6F6F6;
$neutral-color-9: #E9E9E9;
$neutral-color-10: #C8C8C8;
$neutral-color-11: #FDFDFD;
$neutral-color-12: #7A8298;
$neutral-color-13: #D3D8E5;
$neutral-color-14: #B8BDCC;
$neutral-color-15: #E6EBFC;

// Statuses
$green-color-1: #ebfaf4;
$green-color-2: #36b587;
$green-color-3: #A6C316;
$green-color-4: #F3FFBA;
$orange-color-1: #faf6eb;
$orange-color-2: $secondary-color-1;
$red-color-1: #fff0f0;
$red-color-2: #ed1c24;
$red-color-3: #F00C0C;
$red-color-4: #FAEBEB;