/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --table-body-border: 1px solid $font-color-1;
}

table {
    thead th {
        &:first-child {
            border-top-left-radius: 2px;
            border-bottom-left-radius: 2px;
        }

        &:last-child {
            border-top-right-radius: 2px;
            border-bottom-right-radius: 2px;
        }
        background-color: $neutral-color-6;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: $font-color-2;
    }

    tbody tr:not(:last-child) {
        border-block-end: var(--table-body-border) ;
    }

    tr {
        page-break-inside: avoid;
    }
    tr {
        &:nth-of-type(even) {
            background: $neutral-color-8;
            border-radius: 2px;
        }
    }

    tbody {
        tr {
            &:nth-of-type(even) {
                background: $neutral-color-8;
                border-radius: 2px;
            }
    
            &:nth-of-type(1) {
                th,
                td {
                    padding-top: 22px;
                }
            }
        }
    }

    td,
    th {
        padding: 10px;
        text-align: start;
        min-width: 120px;
        vertical-align: top;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $font-color-1;
        @include mobile {
            min-width: 140px;
        }
    }

    td {
        font-weight: 600;
        line-height: 20px;
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
