/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.AvailabilityStatus {
    align-items: center;
    display: flex;

    @include mobile {
        margin-left: -8px;
        margin-right: -8px;
    }

    &-Bar {
        border-radius: 10px;
        height: 12px;
        margin-right: 12px;
        width: 36px;

        &::before {
            border-radius: 10px;
            content: '';
            display: block;
            height: 100%;
            position: relative;
            width: 20px;
        }

        &_color {
            &_green {
                background-color: $status-green-light;

                &::before {
                    background-color: $primary-color-1;
                }
            }

            &_orange {
                background-color: $status-orange-light;

                &::before {
                    background-color: $status-orange-dark;
                }
            }

            &_red {
                background-color: $status-red-light;

                &::before {
                    background-color: $status-red-dark;
                }
            }
        }

        &_size {
            &_0 {
                &::before {
                    width: 0px;
                }
            }

            &_1 {
                &::before {
                    width: 10px;
                }
            }

            &_2 {
                &::before {
                    width: 18px;
                }
            }

            &_3 {
                &::before {
                    width: 24px;
                }
            }

            &_4 {
                &::before {
                    width: 28px;
                }
            }

            &_5 {
                &::before {
                    width: 31px;
                }
            }

            &_6 {
                &::before {
                    width: 100%;
                }
            }
        }
    }

    &-Qty {
        color: $font-color-1;

        @include mobile {
            font-size: 12px;

            @media (max-width: 400px) {
                font-size: 11px;
            }

            @media (max-width: 382px) {
                font-size: 10px;
            }
        }
    }
}