/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

* {
    position: relative;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    font-family: inherit;
    outline: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
    // backface-visibility: hidden;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;

    @media print {
        /* stylelint-disable-next-line declaration-no-important */
        background: transparent !important;
        /* stylelint-disable-next-line declaration-no-important */
        color: #000000 !important;
        /* stylelint-disable-next-line declaration-no-important */
        filter: none !important;
        /* stylelint-disable-next-line declaration-no-important */
        text-shadow: none !important;
    }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
label,
summary {
    display: block;
}

header,
footer,
main {
    font-size: 12px;

    @include mobile {
        font-size: 14px;
    }
}

main {
    --border-width: 1px;

    min-height: calc(100vh - var(--header-height) - var(--breadcrumbs-height));
    max-width: 100vw;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--navigation-tabs-height) + 2 * var(--border-width));
        margin-block-start: 0;
        margin-block-end: var(--footer-total-height);
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
    table-layout: fixed;
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
    }
}

img {
    width: 100%;
}

html {
    font-size: 16px;
}

body {
    font-family: $font-open-sans;
    color: $black;
    scroll-behavior: smooth;
    background-color: #fff;
}

a {
    color: $black;
    text-decoration: inherit;

    &:hover {
        cursor: pointer;
    }
}

span {
    color: $font-color-1;
}

h1 {
    color: $font-color-1;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;

    @include mobile {
        font-size: 36px;
        line-height: 44px;
    }

    +h2,
    +h3,
    +h4 {
        margin-block-start: 7px;

        @include mobile {
            margin-block-start: 8px;
        }
    }
}

h2 {
    color: $font-color-1;
    font-size: 36px;
    line-height: 42px;
    font-style: normal;
    font-weight: 700;

    @include mobile {
        font-size: 30px;
        line-height: 38px;
    }

    +h3,
    +h4 {
        inset-block-start: -9px;
        margin-block-start: 7px;
        margin-block-end: 7px;

        @include mobile {
            inset-block-start: -9px;
            margin-block-start: 8px;
            margin-block-end: 8px;
        }
    }
}

h3 {
    color: $font-color-1;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;

    @include mobile {
        font-size: 26px;
        line-height: 32px;
    }

    +h4 {
        inset-block-start: -9px;
        margin-block-start: 7px;
        margin-block-end: 7px;

        @include mobile {
            inset-block-start: -9px;
            margin-block-start: 8px;
            margin-block-end: 8px;
        }
    }
}

h4 {
    color: $font-color-1;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;

    @include mobile {
        font-size: 22px;
        line-height: 26px;
    }
}

p,
li,
dl {
    color: $font-color-1;
    font-weight: 300;
    font-size: 18px;
    line-height: 24px;

    @include mobile {
        font-size: 16px;
        line-height: 22px;
    }
}

li {
    list-style-type: none;
    list-style-position: inside;
    margin-block-end: 6px;

    // override default blue color for lists in Safari since iOS15

    & * {
        color: var(--color-black);
    }

    ol & {
        counter-increment: list;

        @include mobile {
            counter-increment: list;
        }
    }

    &:last-child {
        margin-block-end: 14px;
    }
}

p {
    margin-block-end: 14px;

    @include mobile {
        margin-block-end: 17px;
        word-break: break-word;
    }
}

input,
select {
    min-height: 24px;
    min-width: 48px;
    font-size: 16px;

    @include mobile {
        min-height: 28px;
        min-width: 56px;
        font-size: 14px;
    }
}

textarea {
    resize: none;

    @include mobile {
        font-size: 16px;
        width: 100%;
    }
}

mark {
    margin-inline-end: 5px;
}

iframe {
    max-width: 100%;
}

a,
input,
button,
textarea,
select,
details {
    @include mobile {
        touch-action: manipulation;
    }
}