/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.ExpandableContent {
    &-Heading {
        font-weight: 600;
        @include desktop {
            font-weight: 600;
        }
    }

    &-Button {
        &_activeOnMobileAndDesktop:not(.ExpandableContent-Button_isContentExpanded) {
            + .ExpandableContent-Content {
                max-height: 0;
                overflow: hidden;
                opacity: 0;
                display: none;
            }
        }
    }
}
