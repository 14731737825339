/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.LogisticMinimum {
    align-items: center;
    background-color: $primary-color-6;
    border: 1px $primary-color-3 solid;
    border-radius: 4px;
    color: $secondary-color-1;
    display: flex;
    font-size: 14px;
    justify-content: center;
    line-height: 24px;
    margin-bottom: 20px;
    padding: 10px 22px;

    @include mobile {
        display: inline-flex;
        font-size: 14px;
        margin: 0 14px 16px;
        padding: 8px;
        width: calc(100% - 28px);

        @media (max-width: 424px) {
            font-size: 13px;
        }

        @media (max-width: 398px) {
            font-size: 12px;
        }

        @media (max-width: 374px) {
            font-size: 11px;
        }

        @media (max-width: 350px) {
            font-size: 10px;
        }
    }

    strong {
        margin-left: 6px;
    }

    svg {
        margin-right: 10px;

        @include mobile {
            height: 20px;
            width: 20px;
        }
    }

    &_isProductCart {
        border-color: $secondary-color-1;
        border-radius: 0;
        display: flex;
        max-width: 460px;
    }

    &_isReached {
        svg {
            &:first-of-type {
                path {
                    stroke: $secondary-color-1;
                }
            }

            &:last-of-type {
                margin-left: 10px;
                transform: scaleX(-1);

                path {
                    stroke: $primary-color-4;
                }
            }
        }
    }
}