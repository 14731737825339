.ProductPagePromoBlock {
    @include desktop {
        margin-bottom: 0;
    }
    
    @include mobile {
        padding-inline: 0;
        margin-bottom: 40px;
    }

    .HighlightedCategories {
        &-SecondBlock {
            @include mobile {
                &:nth-child(1) {
                    img {
                        width: calc(100vw - 30px);
                    }
                }
    
                img {
                    width: calc(50vw - 25px);
                    height: 200px;
                    max-height: 200px;
                }
            }
        }
    }
}