.Typography_h3 {
    font-family: $font-open-sans;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
}

.Typography_h6 {
    font-family: $font-open-sans;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: $font-color-1;
}

.Typography_p1 {
    font-family: $font-open-sans;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $font-color-2;
}

.Typography_body2 {
    font-family: $font-open-sans;
    font-size: 16px;
    line-height: 24px;
    color: $font-color-2;
    margin-block-end: 40px;
}

.Typography_subhead1 {
    font-family: $font-open-sans;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: $white;
}