/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */


input,
textarea,
select {
    border: 1px solid $font-color-3;
    background-color: white;
    border-radius: 2px;
    color: $font-color-1;
    padding: 12px 14px;
    appearance: none;
    caret-color: $font-color-1;

    &:focus {
        border-color: $primary-color-2;
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        /* stylelint-disable-next-line declaration-no-important */
        box-shadow: 0 0 0 30px white inset !important;
        /* stylelint-disable-next-line declaration-no-important */
        background-color: white !important;
    }
}

input {
    height: 48px;
}

textarea {
    width: 300px;

    @include mobile {
        width: 100%;
    }
}

[type='text'] {
    @include mobile {
        width: 100%;
    }
}
