/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.FieldSelect {
    &_isExpanded {
        select {
            border-bottom: none !important;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        .FieldSelect-Clickable {
            &::after {
                content: '';
                display: block;
                height: 1px;
                background-color: $font-color-3;
                width: calc(100% - 16px);
                position: absolute;
                bottom: 0;
                left: 8px;
            }
        }
    }

    &-Select {
        border-radius: 2px;
        
        &_isExpanded {
            border-color: $primary-color-1;
        }

        &_isDisabled {
            border-color: $font-color-3;
        }
    }

    &-Options {
        &_isExpanded {
            @include desktop {
                border-color: $font-color-2;
                background-color: #{$white};
            }
        }
    }

    &-Option {
        padding: 8px 20px 8px;
        margin: 0 8px;
        padding-block: 10px;
        padding-inline-start: 20px;
        background: var(--color-white);
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        @include mobile {
            margin: 0;
        }

        &:hover,
        &:focus {
            @include desktop {
                background: $neutral-color-8;
            }
        }

        &_isHovered {
            @include desktop {
                background: $neutral-color-8;
            }
        }

        &_isDisabled {
            @include mobile {
                border-top: 1px solid $font-color-3;
            }
        }
    }
}
