/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

.SearchItem {
    margin-bottom: 10px;
    padding: 8px 20px;
    &:hover {
        background-color: $neutral-color-6;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    &-Content {
        align-items: center;
        display: flex;
    }
    &-Title {
        font-size: 16px;
        font-weight: 400;
        color: $font-color-1;
    }
    &-Wrapper {
        grid-column-gap: 10px;
    }
}
