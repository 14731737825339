.ThematicSection {
    margin: 0 auto;
    max-width: var(--content-wrapper-width);

    h3 {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 50px;

        @include mobile {
            margin-bottom: 20px;
            font-size: 20px;
        }
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 100px;
        gap: 20px;

        @include desktop {
            padding-left: 20px;
        }

        @include mobile {
            display: flex;
            flex-direction: column;
            gap: 6px;
            margin-bottom: 30px;
        }
    }

    &-Column {
        margin-bottom: 6px;

        li {
            margin-bottom: 8px;

            a {
                font-size: 16px;
                color: $font-color-2;
                line-height: 24px;

            }

            a:hover {
                text-decoration: underline;
                color: $primary-color-1;
            }
        }

        li::before {
            display: none;
        }
    }
}