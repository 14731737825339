/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

:root {
    --required-text-color: #{$error-color};
    --primary-error-color: #{$error-color};
    --primary-success-color: #{$primary-color-2};
}

.Field {
    margin-block-start: 40px;

    @include mobile {
        margin-block-start: 20px;
    }

    &-Label {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: $font-color-2;
        padding-block-end: 6px;

        &_isRequired {
            color: var(--required-text-color);
        }
    }

    &-CheckboxLabel,
    &-RadioLabel {
        color: $font-color-1;

        &:not([class*='_isDisabled']):hover {
            @include desktop {
                color: $font-color-1;
            }
        }

        .input-control {
            @include mobile {
                min-width: 20px;
                min-height: 20px;
            }
        }
    }

    &-Error {
        &Messages {
            margin-top: 4px;
        }

        &Message {
            color: var(--primary-error-color);
            font-weight: 400;
            line-height: 18px;
        }
    }

    input::placeholder,
    textarea::placeholder {
        color: $font-color-3;
    }

    input:focus+.Field-Note {
        opacity: 1;
        height: 100%;
    }

    &_type {
        &_numberWithControls {
            input {
                width: 0 !important;
            }
        }

        &_checkbox,
        &_radio {
            span {
                font-size: 16px;
            }
        }

        &_text,
        &_tel,
        &_textarea,
        &_email,
        &_password,
        &_date,
        &_number,
        &_time,
        &_datetime,
        &_datetime-local,
        &_file,
        &_select {
            &.Field_isValid {

                input,
                textarea,
                select {
                    border: 1px solid var(--primary-success-color);
                }

                .FieldSelect-Options_isExpanded {
                    border-color: var(--primary-success-color);
                }
            }
        }
    }

    [type='checkbox'],
    [type='radio'] {
        min-width: 20px;
        min-height: 20px;

        +.input-control {
            background-color: white;
            border: 2px solid $secondary-color-3;
            border-radius: 1px;
            height: 20px;
            width: 20px;

            +.ProductCustomizableItem,
            +.ProductBundleItem {
                &-Label {
                    flex: 1;
                }
            }

            @include desktop {
                max-width: 20px;
                height: 20px;
            }

            &::after {
                --checkmark-color: transparent;

                background: var(--checkmark-color);
                box-shadow: 2px 0 0 var(--checkmark-color),
                    4px 0 0 var(--checkmark-color),
                    4px -2px 0 var(--checkmark-color),
                    4px -4px 0 var(--checkmark-color),
                    4px -6px 0 var(--checkmark-color),
                    4px -8px 0 var(--checkmark-color);
                inset-inline-start: 15%;
            }

            &:disabled {
                color: $disabled-color;
            }
        }

        &:disabled {
            color: $disabled-color;
        }

        &:hover:not(:disabled)+.input-control {
            @include desktop {
                border-color: $secondary-color-3;

                &::after {
                    @include desktop {
                        --checkmark-color: #{$secondary-color-3};
                    }
                }
            }
        }

        &:checked {
            +.input-control {
                border-color: $secondary-color-3;
                background-color: $primary-color-1;

                &::after {
                    --checkmark-color: #{$secondary-color-3};
                }
            }
        }
    }

    [type='radio'] {
        +.input-control {
            border-radius: 50%;

            &::after {
                box-shadow: none;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: var(--checkmark-color);
                top: 50%;
                inset-inline-start: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}