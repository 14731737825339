/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

:root {
    --footer-nav-height: 64px;
}

.Footer {
    @include mobile {
        margin-bottom: calc(var(--footer-total-height) + 20px);

        &_isUserSignedIn {
            margin-bottom: calc(var(--footer-total-height) + 50px);
        }

        &_isProductPage {
            margin-bottom: calc(var(--footer-total-height) + 80px);
        }

        &_isCartPage, &_isMenu {
            display: none;
        }

        &_isHomePage {
            margin-bottom: calc(var(--footer-total-height) + 15px);
        }
    }

    &-CopyrightContentWrapper {
        background-color: $white;
    }

    &-Content {
        min-height: var(--footer-content-height);
        background-color: $secondary-color-1;
    }

    &-Columns {
        gap: 5%;
        padding-inline: 0;

        @include desktop {
            padding-block-start: 0;
        }

        @include tablet {
            flex-direction: column;
        }

        @include mobile {
            padding: 16px 14px;
        }
    }

    &-Checkout {
        background: $neutral-color-7;
    }

    &-ExpandableContentHeading {
        font-weight: 600;
        font-size: 18px;
        color: $white;
        line-height: 24px;
        margin-block-end: 16px;

        @include desktop {
            margin-block-end: 20px;
        }

        @include mobile {
            margin: 0;
        }
    }

    &-ExpandableContentContent {
        @include desktop {
            margin-block-start: 0;
        }
    }

    &-ExpandableContentButton {
        @include mobile {
            cursor: pointer;
            padding-block: 16px;
        }

        >svg path {
            stroke: $white;
        }
    }

    &-Column {
        &.ExpandableContent {
            @include mobile {
                border: 0;
                margin: 0;
            }

            &:not(:last-of-type) {
                @include mobile {
                    margin: 0;
                }
            }

            &:last-of-type {
                @include mobile {
                    margin-block-end: 0;
                }
            }

            .Footer-ExpandableContentContent_isContentExpanded {
                @include mobile {
                    margin: 0 0 14px 0;
                }
            }
        }
    }

    .ColumnWrapper {
        padding: 50px 0 80px;
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-between;

        @include mobile {
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 0;

        }
    }

    &-ColumnItem {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $neutral-color-9;


        @include mobile {
            text-align: left;
            margin-bottom: 8px;
        }
    }

    &-CopyrightContent {
        justify-content: space-between;
        padding: 20px 0;

        @include tablet {
            flex-direction: column;
        }

        @include mobile {
            padding: 20px;
        }
    }

    &-Copyright {
        padding-inline-start: 0;
        padding-inline-end: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: $font-color-2;

        @include tablet {
            padding-inline-end: 0;
        }

        @include mobile {
            font-weight: 400;
            line-height: 20px;
        }
    }

    &-ColumnContent {

        .ConsultantInfo,
        .ConsultantInfo-Contact {
            @include mobile {
                text-align: left;
            }
        }
    }

    &_isHomePage {
        @include desktop {
            margin-top: 167px;
        } 
    }
}