/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/icons';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/loader';
@import '../../../node_modules/@scandipwa/scandipwa/src/style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

.ConsultantInfo {
    &-Text {
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: $neutral-color-9;

        @include desktop {
            max-width: 255px;
        }

        @include mobile {
            text-align: left;
        }
    }

    &-Intro,
    &-Contact {
        p, a {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: $white;
            text-align: left;
            display: inline-block;
            margin-bottom: 4px;
        }
    }

    &-Intro {
        margin-bottom: 20px;
    }
}
